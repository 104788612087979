import React, { useCallback, useEffect, useState } from 'react';
import { getRoadmapTasks } from '../../../services/roadmapTask/roadmapTaskService';
import SimpleLoader from '../../../components/Loader/SimpleLoader';
import moment from 'moment';
import Table from './Table';
import Timeline from './Timeline';
import SubtaskTable from './SubtaskTable';
import { Button } from '../../../components/forms/Buttons/Button';

const PAGE_SIZE = 100;

const MyRoadmap = ({ roadmap }) => {
  const [tasks, setTasks] = useState([]);
  const [subtasks, setSubtasks] = useState([]);
  // eslint-disable-next-line
  const [tab, setTab] = useState(0);
  const [loadingTasks, setLoadingTasks] = useState(false);

  const getTasksData = useCallback(() => {
    if (!roadmap?.uuid) return;
    setLoadingTasks(true);
    getRoadmapTasks({
      roadmap: roadmap?.uuid,
      page_size: PAGE_SIZE,
    })
      .then((res) => {
        setTasks(res?.results);

        let subtasks = res?.results
          ?.reduce(
            (prev, cur) => [
              ...prev,
              ...cur?.children?.map((ch) => ({
                ...ch,
                parent: { ...cur, children: [] },
              })),
            ],
            [],
          )
          ?.sort((a, b) => (a.start_date > b.start_date ? 1 : -1));
        setSubtasks(subtasks);
      })
      .catch(() => {})
      .finally(() => setLoadingTasks(false));
  }, [roadmap]);

  useEffect(() => {
    getTasksData();
  }, [getTasksData]);

  return (
    <div className='flex flex-col gap-4 p-4'>
      {/* <Description /> */}
      <div className='flex flex-col lg:flex-row gap-2 lg:text-base text-sm'>
        <div className='lg:rounded-md lg:border border-b p-1.5 px-4'>
          {roadmap?.name}
        </div>
        {roadmap && (
          <>
            <div className='lg:rounded-md lg:border border-b p-1.5 px-4'>
              {moment(roadmap.end_date, 'YYYY-MM-DD')?.diff(
                moment(roadmap.start_date, 'YYYY-MM-DD'),
                'week',
              )}{' '}
              weeks
            </div>
            <div className='lg:rounded-md lg:border border-b p-1.5 px-4'>
              {roadmap.task_count} tasks
            </div>
          </>
        )}
      </div>
      <div className='flex flex-row gap-2'>
        <Button
          color={`${tab === 0 && 'primary'}`}
          text='View by task'
          onClick={() => setTab(0)}
        />
        <Button
          color={`${tab === 1 && 'primary'}`}
          text='View by subtask'
          onClick={() => setTab(1)}
        />
      </div>

      <div className='flex flex-row justify-between'></div>
      {loadingTasks ? (
        <div className='flex flex-row justify-center pt-12'>
          <SimpleLoader className={'w-10 h-10'} />
        </div>
      ) : tasks?.length ? (
        <div className='flex flex-col gap-4'>
          {tab === 0 ? (
            <Table tasks={tasks} getTasksData={getTasksData} />
          ) : (
            <SubtaskTable tasks={subtasks} getTasksData={getTasksData} />
          )}
          <Timeline data={tasks} />
        </div>
      ) : (
        <div
          className='flex flex-row justify-center py-8 mt-4 text-gray-400 text-lg
          border rounded-lg'
        >
          No content
        </div>
      )}
    </div>
  );
};

export default MyRoadmap;
