// import React, { useCallback, useContext, useEffect, useState } from 'react';
// import { UserContext } from '../../../utils/contexts/UserContext';
// import {
//   deleteDraftRoadmap,
//   deleteRoadmap,
//   getDraftRoadmaps,
//   getRoadmaps,
// } from '../../../services/roadmapTask/roadmapService';
// import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
// import moment from 'moment';
// import { useTranslation } from 'react-i18next';
// import { PlayIcon } from '@heroicons/react/24/solid';
// import { useNavigate } from 'react-router-dom';
// import CircleProgressBar from '../../../components/charts/CircleProgressBar';
// import { Button } from '../../../components/forms/Buttons/Button';
// import { toast } from 'react-toastify';
// import { format, formatDistance } from 'date-fns';

import { useContext, useEffect } from 'react';
import { UserContext } from '../../../utils/contexts/UserContext';
import OrgRoadmapStats from './OrgRoadmapStats/OrgRoadmapStats';
import { useState } from 'react';

const Roadmaps = () => {
  const { user } = useContext(UserContext);
  const [lvl, setLvl] = useState(0);

  useEffect(() => {}, []);

  return (
    <>
      <OrgRoadmapStats />
    </>
  );
};

export default Roadmaps;
