import { useContext, useEffect, useMemo, useState } from 'react';
import { UserContext } from '../../../../utils/contexts/UserContext';
import { getOrgRoadmapStats } from '../../../../services/roadmapTask/roadmapStatsService';

const OrgRoadmapStats = () => {
  const { user } = useContext(UserContext);
  const [schools, setSchools] = useState([]);
  const [ordering, setOrdering] = useState();

  const headers = useMemo(
    () => [
      { title: 'School', key: 'name' },
      { title: 'Students', key: 'total_students' },
      { title: 'Roadmaps', key: 'has_roadmap' },
    ],
    [],
  );

  useEffect(() => {
    getOrgRoadmapStats()?.then((res) => {
      console.log(res);
      setSchools(res?.results);
    });
  }, []);

  return (
    <div className='flex flex-col py-4 border rounded-lg'>
      <table className='table-auto'>
        <thead>
          <tr className=''>
            <th className='text-center px-4 w-1/12'>#</th>
            {headers?.map((h, i) => (
              <th
                className={`${!i && 'text-left'} w-min max-w-min`}
                key={h.key}
              >
                {h.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {schools?.map((s, i) => {
            return (
              <tr className='even:bg-gray-100'>
                <td className='text-center p-2 px-4 w-1/12'>{i + 1}</td>
                {headers?.map((h, i) => (
                  <td
                    className={`${i ? 'text-center' : ''} max-w-xs`}
                    key={h.key}
                  >
                    {s[h.key]}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default OrgRoadmapStats;
